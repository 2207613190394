
import { Namespace } from '@/store/types'
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

@Component({})
export default class ApiErrorVue extends Vue {
  @State('apiError', { namespace: Namespace.Utils }) public apiError!: string

  @Action('resetApiError', { namespace: Namespace.Utils }) public resetApiError: any
}

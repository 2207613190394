
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class GenericErrorVue extends Vue {
  private genericErrorVisible: boolean = false
  private genericError: string = ''

  public ShowError(err: string) {
    if (err.length === 0) {
      return
    }

    this.genericError = err
    this.genericErrorVisible = true
  }

  public HideError() {
    this.genericErrorVisible = false
    this.genericError = ''
  }
}
